import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    lang: "EN",
  },
  getters: {
    lang: (state) => state.lang,
  },
  mutations: {
    setLang(state, data) {
      state.lang = data;
    },
    reset(state, data) {
      console.log(data);
      state.lang = "EN";
    },
  },
  actions: {
    setLang(context, data) {
      context.commit("setLang", data);
    },
    reset(context, data) {
      context.commit("reset", data);
    },
  },
  modules: {},
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      reducer(val) {
        return {
          lang: val.lang,
        };
      },
    }),
  ],
});
